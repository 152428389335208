import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/use-siteMetadata';
import Layout from '../components/layout';
import { CardProducts } from './common';
import Pagination from '../components/pagination';
import ProductListItem from '../components/product-list-item';
import mapProduct from '../common/map-product';
import { getCanonicalUrl } from '../common/utils';
import CATEGORIES from '../../data/categories.json';

function getDescription(category) {
  return `Buy ${category} online in Pakistan. Order ${category} online in Karachi, Lahore, Islamabad & all across Pakistan. Cash on delivery.`;
}

export const query = graphql`
  query($regex: String!, $limit: Int!, $skip: Int!) {
    allMdx(
      filter: {frontmatter: {categories: {regex: $regex}}}
      sort: {fields: frontmatter___title}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          discontinued
          slug
          title
          description
          categories
          price
          images
        }
      }
    }
  }
`;

export default ({
  data: { allMdx: { nodes: products } },
  pageContext: { key, currentPage, totalPages }
}) => {
  const category = CATEGORIES[key];
  const { siteUrl } = useSiteMetadata();

  function getCanonicalSlug(pageNo) {
    return `category/${key}` + (pageNo > 1 ? `/${pageNo}` : '');
  }

  return (
    <Layout
      title={category + ' | PasteWax Pakistan'}
      description={getDescription(category)}
      slug={getCanonicalSlug(currentPage)}
    >
      <Helmet>
        {currentPage < totalPages && <link rel="next" href={getCanonicalUrl(siteUrl, getCanonicalSlug(currentPage + 1))} />}
        {currentPage > 1 && <link rel="prev" href={getCanonicalUrl(siteUrl, getCanonicalSlug(currentPage - 1))} />}
      </Helmet>

      <h2>{category}</h2>
      <CardProducts>
        {products.map(p => {
          const product = mapProduct(p);
          return <ProductListItem key={product.slug} product={product}></ProductListItem>;
        })}
      </CardProducts>

      <Pagination type="category" urlKey={key} currentPage={currentPage} totalPages={totalPages}></Pagination>
    </Layout>
  );
};
